import React from "react";
import Link from "gatsby-link";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

export type INavigator = {
  pageContext: {
    previousPagePath?: string;
    nextPagePath?: string;
    // previousPageId: string,
    // nextPageId: string
  };
};

const Navigator: React.FC<INavigator> = ({ pageContext }) => {
  return (
    <div className="dx-p2 mt-[72px] mb-16  flex w-full items-center justify-between leading-[18.75px] no-underline xl:mb-12 [&_a]:text-dsc-theme-accent">
      {pageContext?.previousPagePath && (
        <Link to={`/news/${pageContext?.previousPagePath}`}>
          <ChevronLeftIcon className="inline h-4 w-4 align-middle" /> 前へ
        </Link>
      )}
      {pageContext?.nextPagePath && (
        <Link to={`/news/${pageContext?.nextPagePath}`} className="ml-auto">
          次へ <ChevronRightIcon className="inline h-4 w-4 align-middle" />
        </Link>
      )}
    </div>
  );
};
export default Navigator;
