import React, { useEffect } from "react";
import parse from "react-html-parser";
// import "moment/locale/ja";
import Sidebar from "./Sidebar";
import Navigator from "./Navigator";

export type IBlog = {
  title: string;
  categories?: {
    name: string;
    slug: string;
  }[];
  htmlString: string;
  releaseDate: string;
};

type ContentType = {
  contents: IBlog;
  pageContext: {
    previousPagePath?: string;
    nextPagePath?: string;
    // previousPageId: string;
    // nextPageId: string;
  };
};

const baseUrl =
  process.env.GATSBY_STRAPI_URL ||
  "https://strapi-admin.drone.digitalstacks.net/";

const ContentArea: React.FC<ContentType> = ({ contents, pageContext }) => {
  const { title, categories, htmlString, releaseDate } = contents;
  const overridedHtmlStr = htmlString
    .replace(/\/uploads\//g, `${baseUrl}/uploads/`)
    .replace(/<img/g, `<img loading="lazy`);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll(".blog-details a").forEach((link) => {
        if (link instanceof HTMLAnchorElement && link.href) {
          if (
            !link.href.includes("http://localhost:8000") ||
            !link.href.includes("https://yourdomain.tld")
          ) {
            link.setAttribute("target", "_blank");
          }
        }
      });
    }
  }, []);

  return (
    <section className="my-20">
      <div className="mb-4 flex items-center gap-x-4">
        <p className="min-w-[115px] text-base leading-4 text-dsc-theme-mute">
          {releaseDate}
        </p>
        {categories && (
          <div className="flex flex-wrap gap-2">
            {categories?.map((category) => (
              <p
                className="inline-block rounded-full bg-dsc-theme-link px-3 text-xs leading-6 text-dsc-theme-accent md:py-1"
                key={category.name}
              >
                {category.name}
              </p>
            ))}
          </div>
        )}
      </div>
      <h1 className="dsc-h1 mb-[46px] font-bold leading-[60px] text-dsc-theme-title">
        {title}
      </h1>
      <div className=" flex flex-col justify-between gap-x-8 lg:flex-row">
        <div className="w-full lg:w-[65%] lg:max-w-[780px]">
          <div
            className="prose-p:dx-p2 prose-h1:dx-h1 prose-h2:dx-h2 prose-h3:dx-h3 [&_*]:!font-main prose max-w-none text-dsc-theme-body prose-headings:my-4 prose-h2:mt-10 prose-h2:leading-9 prose-h3:mt-10 prose-h3:leading-7 prose-p:my-0 prose-p:leading-7 prose-a:font-normal prose-a:text-dsc-theme-accent prose-a:no-underline
            prose-code:p-6 prose-pre:my-6 prose-pre:w-full prose-pre:whitespace-pre-wrap prose-pre:break-words prose-pre:rounded-md prose-pre:bg-dsc-theme-pre-bg prose-pre:p-2 prose-ol:my-2 prose-ol:list-decimal prose-ol:pl-6 prose-ul:my-2 prose-ul:list-disc prose-ul:pl-6 prose-hr:my-4 prose-h2:md:leading-[46px] [&_br]:hidden [&_p_br]:block [&_img]:my-6 [&_a_*]:!break-all [&_a_*]:text-dsc-theme-accent [&_a_*]:!no-underline"
          >
            {parse(overridedHtmlStr)}
          </div>

          <Navigator pageContext={pageContext} />
        </div>
        <Sidebar />
      </div>
    </section>
  );
};
export default ContentArea;
