import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import React from "react";
import FancyTitle from "./FancyTitle";

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBlog(limit: 10, sort: { fields: published_date, order: DESC }) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `);
  const blogs = data.allStrapiBlog.edges;
  return (
    <div className="w-full lg:w-[35%] lg:max-w-[343px]">
      <div className="blog-sidebar">
        <div>
          <div>
            <FancyTitle title={"最新の投稿"} />
          </div>
          <div className={`mb-14 mt-6 flex flex-col gap-y-6`}>
            {blogs.map((blog) => (
              <Link
                to={`/news/${blog.node.slug}/`}
                key={blog.node.title}
                className="text-base leading-[22px] tracking-[0.5px] text-dsc-theme-body transition-all duration-300 ease-in-out line-clamp-2 hover:text-dsc-theme-accent"
              >
                {blog.node.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
