import React from "react";

type PropsType = {
  title: string;
};

const FancyTitle: React.FC<PropsType> = ({ title }) => {
  return (
    <div className="relative pb-4">
      <p className="text-[22px] font-bold leading-7 tracking-[0.5px] text-dsc-theme-title md:text-2xl">
        {title}
      </p>
      <div className="absolute bottom-0 flex items-center gap-x-[5px]">
        <div className="h-[5px] w-[30px] rounded-full bg-dsc-theme-accent"></div>
        <div className="h-[5px] w-[5px] rounded-full bg-dsc-theme-accent"></div>
        <div className="h-[5px] w-[5px] rounded-full bg-dsc-theme-accent"></div>
      </div>
    </div>
  );
};
export default FancyTitle;
