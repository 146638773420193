import React from "react";
import { graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import Layout from "../layout";
import ContentArea, {
  IBlog,
} from "../components/common/news/blog-details/ContentArea";
import SEO from "../components/seo";

const BlogListTemplate = ({ data, pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  console.log({ pageContext });

  const next = nextPagePath ? `${nextPagePath}/` : undefined;
  const prev = previousPagePath ? `${previousPagePath}/` : undefined;

  console.debug({ data });

  const blog: IBlog = {
    title: data.strapiBlog.title,
    htmlString: data.strapiBlog.body.data.body,
    releaseDate: data.strapiBlog.published_date,
  };

  return (
    <Layout>
      <div className="px-5 md:px-8">
        <ContentArea
          contents={blog}
          pageContext={{
            previousPagePath: next,
            nextPagePath: prev,
            // previousPageId: "",
            // nextPageId: "",
          }}
        />
      </div>

      <div className="containerWrapper my-[50px]"></div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    strapiBlog(id: { eq: $id }) {
      title
      published_date(locale: "ja", formatString: "ll")
      slug
      body {
        data {
          body
        }
      }
      seo {
        title
        description
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export const Head = ({ location, data }) => {
  const seo = {
    title: data.strapiBlog.seo.title,
    description: data.strapiBlog.seo.description,
    image: data.strapiBlog.seo.image.localFile.publicURL,
  };
  return <SEO path={location.pathname} {...seo} />;
};

export default BlogListTemplate;
